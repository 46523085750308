import React from "react";
import "./HomePageAbout.css";
import HomePageImage from "../../images/CBSE_image.png";
const HomePageAbout = () => {
  return (
    <div className="home-page-container">
      <div className="home-page-left-image-container">
        <img id="home-page-image" src={HomePageImage} alt="" />
      </div>
      <div className="home-page-right-container">
        <div className="home-page-heading">
          Fostering Success at Chatterjee Noble School: Empowering Students
          Through CoEd English Medium CBSE Education.
        </div>
        <div className="home-page-content">
          Immerse yourself in a world of educational distinction at Chatterjee
          Noble School, centrally located in the vibrant heart of Dumdum. As
          Kolkata’s leading CoEd English Medium CBSE School, we passionately
          share our commitment to early childhood education with the community.{" "}
          <br /> <br />
          Our institution stands as the epitome of CBSE academic excellence,
          dedicated to shaping well-rounded individuals through a comprehensive
          curriculum that not only expands the mind but also opens doors to
          limitless opportunities. Join us on a journey that prioritizes
          scholastics, personal growth, creativity, and character development,
          setting the stage for a future of unparalleled success. Our commitment
          extends beyond legacy to forge well-rounded global citizens. Embracing
          change with ambition, our CoEd English Medium CBSE School creates a
          safe and inspiring learning environment tailored for today’s
          independent students. <br />
          <br />
          Join us in shaping the future with a holistic approach that aligns
          seamlessly with the dynamic landscape of the world. At Chatterjee
          Noble School, we go beyond education to instill essential concepts and
          skills in your child, cultivating not just academic excellence but
          also confidence and motivation. Our commitment lies in shaping a
          foundation for success, where comprehensive learning meets the unique
          needs of every student in our CoEd English Medium CBSE School. <br />{" "}
          <br />
        </div>
      </div>
    </div>
  );
};

export default HomePageAbout;
