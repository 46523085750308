import React, { useState, useEffect } from 'react';
import HeroImage from '../components/HeroImage';
import '../styles/Gallery.css';

import { getRestClient } from '../services';
import { readItems } from '@directus/sdk';

// const fetchImages = async () => {
//   try {
//     const client = getRestClient();
//     const response = await client.request(
//       readItems('gallery', {
//         fields: [
//           {
//             images: ['*'],
//           },
//         ],
//       }),
//     );

//     return response?.images || [];
//   } catch (error) {
//     console.log('📢[Faculty.jsx:62]: error: ', error);
//     return [];
//   }
// };

const Gallery = () => {
  // const [loading, setLoading] = useState(false);
  // const [galleryImages, setGalleryImages] = useState([]);

  const school_infrastructures = [
    { type: 'Laboratory', image: 'images/scholarship.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h1.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h2.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h3.jpeg', name: '' },
    // { type: 'Laboratory', image: 'images/h4.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/Durga.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/Durga2.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/Girls football.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/halloween.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/Saregamapa.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/selfie.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves1.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves2.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves3.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves4.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves5.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves6.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves7.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves8.jpg', name: '' },
    { type: 'Laboratory', image: 'images/gellery/waves9.jpg', name: '' },
    // { type: 'Laboratory', image: 'images/h5.jpeg', name: '' },
    { type: 'Laboratory', image: 'images/h6.jpeg', name: '' },
  ];
  // const [infras, setInfras] = useState(school_infrastructures);
  // const getFilterdData = (updatedInfras) => {
  //   setInfras(updatedInfras);
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   fetchImages().then((res) => {
  //     setGalleryImages(res);
  //     setLoading(false);
  //   });
  // }, []);

  return (
    <>
      <HeroImage />
      {/* this section will be needed when it will be connected to server  */}
      {/* {console.log(galleryImages)}
      <div
        style={{
          textAlign: 'center',
          paddingTop: '4.5rem',
          paddingBottom: '4.5rem',
          marginBottom: '30px',
          color: '#0e2245',
        }}>
        <h1>Gallery</h1>
      </div> */}
      {/* <div className='container'>
        {loading && (
          <div className='tw-w-full'>
            <div className='tw-py-8 tw-text-center'>Loading...</div>
          </div>
        )}
        <div className='row'> */}
          {/* <InfraFilter getFilterdData={getFilterdData} school_infrastructures={school_infrastructures}/> */}
          {/* I have commented out the school infra filter */}
        {/* </div>
        <div className='gallery_container'>
          <div className='row'>
            {galleryImages.map((galleryImage) => (
              <div className='col-lg-3 col-md-6 col-12'>
                <div className='Gallery_Image_container'>
                  <img
                    src={`https://cns-admin.technohubnetwork.com/assets/${galleryImage.directus_files_id}`}
                    alt='schoolImg'
                    width='250px'
                    height='250px'
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* the above section is needed when it is connected to the server  */}
      <div className='tw-mb-20 tw-mt-16 tw-size-3 tw-text-3xl tw-ml-auto tw-mr-auto'>Gallery</div>
      <div className="container">
        <div className="row">
          {school_infrastructures.map((infra)=>(
            <div className="col-lg-3 col-md-6 col-12 tw-mb-3 tw-p-1">
              <img src={infra.image} alt="" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Gallery;
